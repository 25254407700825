import { Button, Col, Divider, Row, Text } from '../index';
import { Spin } from 'antd';
import React from 'react';
import { SuccessCheckmarkIcon } from '../../assets';
import {
  AdditionalStyleControlProps,
  BodyProps,
  CloseProps,
  FooterProps,
  OkProps,
  SpinnerProps,
  SuccessProps,
} from './types.ts';
import { createGlobalStyle, css } from 'styled-components';
import { desktopWidth, useIsMobile } from '../../hooks/useBreakPoint';
import Icon from '../Icon';
import { px } from '../../utils';
import { ButtonKind } from '../Button/Button.types';
import { TypographyProps, padding } from 'styled-system';

export const Success = ({
  titleSuccess,
  ...restProps
}: SuccessProps & TypographyProps) => {
  const isMobile = useIsMobile();
  return (
    <Row wide flexCenter height="100%" gap={isMobile ? '10px' : '18px'}>
      <Text
        lineHeight="24px"
        fontSize="16px"
        textAlign="center"
        fontWeight="700"
        {...restProps}
      >
        {titleSuccess}
      </Text>
      <Icon>
        <SuccessCheckmarkIcon />
      </Icon>
    </Row>
  );
};

export const GlobalModalStyle = createGlobalStyle(
  ({
    success,
    full,
    noContentPadding,
    noBodyPadding,
    isMobile,
    padding,
    drawerHeight,
  }: AdditionalStyleControlProps) => css`
    .ant-modal .ant-modal-close {
      ${isMobile ? 'top: 18px; right: 20px;' : 'top: 18px; right: 20px;'}
    }

    .ant-drawer .ant-drawer-content .ant-drawer-body {
      padding: 30px 20px 20px;
    }

    .ant-drawer.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
      height: auto !important;
    }

    ${!success &&
    full &&
    css`
      .ant-drawer.ant-drawer-bottom.ant-drawer-open
        .ant-drawer-content-wrapper {
        height: calc(100% - 40px) !important;
      }
    `}

    ${drawerHeight &&
    css`
      .ant-drawer.ant-drawer-bottom.ant-drawer-open
        .ant-drawer-content-wrapper {
        height: ${drawerHeight} !important;
      }
    `}

    ${noContentPadding &&
    css`
      .ant-modal .ant-modal-content,
      .ant-drawer .ant-drawer-content {
        padding: 0;
      }
    `}
    ${noBodyPadding &&
    css`
      .ant-drawer .ant-drawer-content .ant-drawer-body {
        padding: 0 !important;
      }
    `}

    ${padding &&
    css`
      .ant-drawer .ant-drawer-content .ant-drawer-body,
      .ant-modal .ant-modal-content .ant-modal-body {
        padding: ${padding} !important;
      }
    `}
  `
);

export function Ok({
  onOk,
  textOk,
  success,
  onSuccessOk,
  setSuccess,
  onClose,
  textSuccessOk,
  children,
  validateForm,
  isFormValid,
  ...restProps
}: OkProps) {
  let handleOk = onOk;

  if (success) {
    handleOk = () => {
      onSuccessOk && onSuccessOk();
      setSuccess(false);
      onClose();
    };
  }

  const content = children ?? textOk;

  return content && onOk ? (
    <Button
      kind="modal"
      key="OK"
      bg="green"
      fontSize="14px"
      onClick={handleOk}
      {...restProps}
    >
      {success ? textSuccessOk : content}
    </Button>
  ) : null;
}

export function Close({
  onClose,
  textClose,
  children,
  success,
  ...restProps
}: CloseProps) {
  if (success) return null;

  const content = children ?? textClose;

  return content && onClose ? (
    <Button
      kind="modal"
      key="cancel"
      bg="red"
      fontSize={'14px'}
      onClick={onClose}
      {...restProps}
    >
      {content}
    </Button>
  ) : null;
}

export function Footer({
  isMobile,
  vertical,
  divider = true,
  buttonProps,
  largeButtons,
  blueOk,
  mobileCloseButton = true,
  success,
  isFormValid,
  ...restProps
}: FooterProps) {
  const okProps = {
    ...restProps.okProps,
    ...buttonProps,
    ...(!success && blueOk && { bg: 'blue' }),
    ...(!success && largeButtons && { kind: 'normal' as ButtonKind }),
  };

  const closeProps: CloseProps = {
    ...restProps.closeProps,
    ...buttonProps,
    ...(!success && largeButtons && { kind: 'normal' as ButtonKind }),
    ...(!mobileCloseButton && isMobile && { onClose: undefined }),
  };

  if (!(okProps.textOk && okProps.onOk) && !closeProps.textClose) return null;

  if (vertical) {
    return (
      <>
        {divider && <Divider my="20px" />}
        <Row wide gap="12px" mt={!divider && '20px'}>
          <Ok width="100%" kind="modal" {...okProps} />
          <Close width="100%" kind="modal" {...closeProps} />
        </Row>
      </>
    );
  }

  return (
    <>
      {divider && <Divider my="20px" />}
      <Col
        mt={!divider && '20px'}
        gap={isMobile ? '12px' : '2rem'}
        flexCenter
        width="100%"
        gridAutoColumns={'minmax(max-content, 140px)'}
      >
        <Ok width="100%" kind="modal" {...okProps} />
        <Close width="100%" kind="modal" {...closeProps} />
      </Col>
    </>
  );
}

export function Body({
  children,
  rootBoxProps,
  footer,
  isMobile,
  drawer,
  customDesktopWidth,
  full,
  bodyHeight,
  centerBodyElements = true,
}: BodyProps) {
  return (
    <Row
      wide
      minWidth={isMobile ? 'auto' : px(customDesktopWidth || desktopWidth - 40)}
      maxWidth={isMobile ? '100%' : 'max-content'}
      minHeight={bodyHeight || '110px'}
      height="100%"
      gridAutoRows="1fr max-content"
      {...rootBoxProps}
    >
      <Row
        height="100%"
        wide
        justifyItems={centerBodyElements && 'center'}
        alignItems={centerBodyElements && 'center'}
        gridAutoRows="auto"

        // overflowX={full && isMobile ? 'hidden' : undefined}
        // overflowY={full && isMobile ? 'visible' : undefined}
      >
        {children}
      </Row>
      {footer && (
        <Row wide justifyItems="center">
          {footer}
        </Row>
      )}
    </Row>
  );
}

export function Spinner({ loading }: SpinnerProps) {
  return (
    <Spin
      css={css`
        pointer-events: ${loading ? 'auto' : 'none'};
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        justify-items: center;
        align-content: center;
        background-color: #ffffff88;
      `}
      tip={null}
      spinning={loading}
      size="default"
    ></Spin>
  );
}
