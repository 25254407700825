import React, { Component, ErrorInfo, ReactNode } from 'react';
import ContainerFullPage from '../ContainerFullPage';
import { Row, Flex, Button } from '@keyslabor/shared/ui';
import AppGlobalStyle from '@keyslabor/shared/containers/AppGlobalStyle';
import AppGlobalStyleOverride from '../../containers/App/AppGlobalStyle';
import theme from '@keyslabor/shared/theme';
import * as Sentry from '@sentry/react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    Sentry.captureException({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <AppGlobalStyle theme={theme} />
          <AppGlobalStyleOverride theme={theme} />
          <ContainerFullPage>
            <Row flexCenter gap="20px" textAlign="center">
              <Flex>
                Something went wrong. Please refresh the page and try again
              </Flex>
              <Flex>
                <Button
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Refresh
                </Button>
              </Flex>
            </Row>
          </ContainerFullPage>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
