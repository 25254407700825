import { useCallback, useEffect, useMemo, useState } from 'react';

interface Props {
  onHide?(): void;
}

export default function useModal(props?: Props) {
  const { onHide } = props ?? {};
  const [show, setShow] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToggle = useCallback(() => {
    setShow(!show);
  }, [show]);

  const handleClose = useCallback(() => setShow(false), []);
  const handleOpen = useCallback(() => setShow(true), []);

  const modalProps = useMemo(
    () => ({
      loading,
      onClose: handleClose,
      onOpen: handleOpen,
      onToggle: handleToggle,
      open: show,
      setLoading,
      setShow,
      setSuccess,
      show,
      success,
    }),
    [handleClose, handleOpen, handleToggle, loading, show, success]
  );

  useEffect(() => {
    if (!show) {
      onHide && onHide();
      setSuccess(false);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return useMemo(
    () => ({
      ...modalProps,
      modalProps,
    }),
    [modalProps]
  );
}

export type ModalControlProps = ReturnType<typeof useModal>;
