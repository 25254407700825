import {FlexProps} from "@keyslabor/shared/ui/layout/Flex/Flex.types.ts";
import {useRef, useState} from "react";
import {Box, Flex} from "@keyslabor/shared/ui";
import {ArrowDownIcon, ArrowUpIcon} from "@/assets";

export const CollapsibleQuestionAnswer = ({
                                              children,
                                              ...restProps
                                          }: {
    children: string;
} & FlexProps) => {
    const COLLAPSED_ANSWER_LENGTH = 50;
    const [isCollapsed, setIsCollapsed] = useState(true);
    const ref = useRef<HTMLDivElement>(null);
    const isCollapsable = children.length > COLLAPSED_ANSWER_LENGTH;

    return (
        <Flex ref={ref} position="relative" flexDirection="row" zIndex={100}>
            {!isCollapsed && <Box height="59px"> </Box>}
            <Flex
                boxShadow="0px 4px 4px 0px rgba(20, 57, 128, 0.1)"
                borderRadius="10px"
                p="11px 10px"
                flexDirection="row"
                backgroundColor="white"
                position={isCollapsed ? 'relative' : 'absolute'}
                minWidth="335px"
                height={isCollapsed ? '54px' : 'max-content'}
                minHeight="54px"
                maxHeight={isCollapsed ? '54px' : '300px'}
                overflow="auto"
                zIndex={isCollapsed ? 0 : 1000}
                alignItems={'center'}
                {...restProps}
            >
                <Flex
                    flexGrow={1}
                    fontSize="14px"
                    lineHeight="16px"
                    onClick={() => {
                        setIsCollapsed(!isCollapsed);
                    }}
                >
                    {(isCollapsed && isCollapsable) ? children?.slice(0, COLLAPSED_ANSWER_LENGTH) + '...' : children}
                </Flex>

                <Flex
                    width="20px"
                    minWidth="20px"
                    position="relative"
                    onClick={() => {
                        setIsCollapsed(!isCollapsed);
                    }}
                    css="
            & > svg > path {
              fill: #143980 !important;
              opacity: 1 !important;
            }
          "
                >
                    {isCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </Flex>
            </Flex>
        </Flex>
    );
};
