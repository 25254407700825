import 'styled-components';
import { Flex } from '@keyslabor/shared/ui';
import { useQuestionsAnswers } from '@/hooks/useQuestionsAnswers';
import { useInterviewPreview } from '@/api/services';
import {Fragment, useMemo, useRef} from 'react';
import VideoPlayer from '@/components/VideoPlayer';
import { Answer } from '@/api/typesApi';
import styled, { createGlobalStyle } from 'styled-components';
import colors from '@keyslabor/shared/theme/colors';
import { extractQuestionNumber } from '@/utils/format';
import InterviewLeadInfo from "./InterviewLeadInfo.tsx";
import {Question, QuestionWrapper, VideoWrapper} from "./components/Question.tsx";
import {mobileBreakpoint, useIsMobile} from '@keyslabor/shared/hooks/useBreakPoint';
import InterviewCarousel from "./components/InterviewCarousel.tsx";
import InterviewSlider from "@/pages/InterviewPreview/components/InterviewSlider.tsx";
import {px} from "@keyslabor/shared/utils.ts";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    overflow: hidden !important;
    scroll-behavior: smooth;
  }
`;

const InterviewPageWrapper = styled(Flex)`
  padding: 0px !important;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.grayInterviewPreviewBg};
  overflow: hidden;

  @media screen and (min-width: ${px(mobileBreakpoint)}) {
    height: 100dvh;
    min-height: 100dvh;
    max-height: 100dvh;
  }
`;

function InterviewPreview() {
  const { invitationKey } = useQuestionsAnswers();
  const isMobile = useIsMobile();

  const { data } = useInterviewPreview({
    invitation_key: invitationKey,
  });

  const [answers, totalQuestions] = useMemo(() => {
    const answers = data?.answers ?? [];

    const totalQuestions = Math.max(...answers.map((answer) => {
      return extractQuestionNumber(answer?.question_name);
    }))

    return [
        answers.sort(sortAnswersByQuestionName),
        totalQuestions
    ] as const;
  }, [data?.answers]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  // @ts-expect-error - GlobalStyle type is not compatible with JSX but works
  const globalStyles = <GlobalStyle />
  const ListRendererComponent = isMobile ? InterviewSlider : InterviewCarousel;

  return (
    <>
      {globalStyles}
      <InterviewPageWrapper ref={wrapperRef}>
        {data && !isMobile ? <InterviewLeadInfo data={data} /> : null}

        {data ? (
            <ListRendererComponent>
              {answers.map((answer, index) => {
                    const questionNumber = extractQuestionNumber(answer?.question_name);

                    return (
                        <Fragment key={index}>
                          {index === 0 ? (
                              <QuestionWrapper>
                                <Flex fontSize="20px" fontWeight="700" color="#143980" gridRow={isMobile ? "1 / 3": "1 / 2"}>
                                  {isMobile ? <InterviewLeadInfo data={data} /> : `Introduction`}
                                </Flex>
                                <VideoWrapper gridRow={isMobile ? "3 / 4" : "2 / 4"}>
                                  <VideoPlayer src={answer.video_processed_url} controls />
                                </VideoWrapper>
                              </QuestionWrapper>
                          ) : (
                              <Question answer={answer} questionNumber={questionNumber} totalQuestions={totalQuestions} />
                          )}
                        </Fragment>
                    )
                  }
              )}
            </ListRendererComponent>
        ) : null}
      </InterviewPageWrapper>
    </>
  );
}

const sortAnswersByQuestionName = (a: Answer, b: Answer) => {
  const aNum = extractQuestionNumber(a.question_name);
  const bNum = extractQuestionNumber(b.question_name);
  return aNum - bNum;
};

export default InterviewPreview;
