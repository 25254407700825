import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './containers/App/App';
import { BrowserRouter } from 'react-router-dom';
import AppContext from '@keyslabor/shared/containers/AppContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import ErrorBoundary from './components/ErrorBoundary';
import './i18n';

window['WEEKS_OFFSET'] = 0;
window['FIRST_DAY_OFFSET'] = 1;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: 0,
      staleTime: 1000 * 5, // 5 seconds
    },
  },
});

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  integrations: [],
  enabled: !!import.meta.env.VITE_SENTRY_DNS,
  beforeSend(event) {
    /// Send localStorage and sessionStorage data to Sentry
    const localStorageData = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        localStorageData[key] = localStorage.getItem(key);
      }
    }

    const sessionStorageData = {};
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key) {
        sessionStorageData[key] = sessionStorage.getItem(key);
      }
    }

    event.extra = {
      ...event.extra,
      localStorage: localStorageData,
      sessionStorage: sessionStorageData,
    };

    return event;
  },
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AppContext>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </QueryClientProvider>
    </AppContext>
  </StrictMode>
);
