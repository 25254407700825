import { colors } from './colors';
import { typography } from './typography';

export { antdColorsConfig } from './colors';
export { antdTypographyConfig } from './typography';

export type Theme = typeof theme;

export type BreakpointsProp = Array<string> & {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
};

const breakpoints: BreakpointsProp = ['40em', '52em', '64em', '80em'];

// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const theme = {
  colors,
  typography,
  breakpoints,
};

export default theme;
