import { BoxStyledProps } from '../layout/Box/Box.types';
import React from 'react';
import { IconStyled } from './styled';
import { IconProps } from './types';
import { convertThemeColorNamesToHash } from '@keyslabor/shared/utils';
import { useTheme } from 'styled-components';

export default function Icon({
  children,
  onClick,
  ref,
  ...restProps
}: React.PropsWithChildren<IconProps & BoxStyledProps>) {
  const theme = useTheme();
  const { disabled } = restProps;
  const propsWithColors = convertThemeColorNamesToHash(restProps, theme);
  return (
    <IconStyled
      ref={ref}
      onClick={disabled ? undefined : onClick}
      {...propsWithColors}
    >
      {children}
    </IconStyled>
  );
}
