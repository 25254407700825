import {InterviewPreviewOutput, Shift} from "@/api/typesApi.ts";
import {Flex, Grid} from "@keyslabor/shared/ui";
import dummyAvatar from "./assets/dummyAvatar.svg";
import styled from "styled-components";
import {useIsMobile} from "@keyslabor/shared/hooks/useBreakPoint.ts";
import {FC} from "react";

const shifts: Record<Shift, string> = {
    am: 'am',
    pm: 'pm',
    overnight: 'overnight',
    flexible: 'flexible',
    late_closer: 'late_closer',
};

const Card = styled(Flex)`
    background-color: white;
    color: black;
    margin-top: 10px;
    box-shadow: 0px 4px 4px 0px rgba(20, 57, 128, 0.1);
    width: 335px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
`;

const PositionGrid = styled(Grid)`
    font-size: 18px;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 2fr 1fr;
    width: 100%;
    justify-items: stretch;
`;

const InterviewLeadInfo: FC<{ data: InterviewPreviewOutput }> = ({ data }) => {
    const isMobile = useIsMobile();
    const isLongShiftTitle = [
        shifts.flexible,
        shifts.late_closer,
        shifts.overnight,
    ].includes(data.shift as Shift);

    const leadNameFragment = (
        <Flex
            pr="5px"
            overflow="hidden"
            fontWeight="500"
            css="white-space: nowrap; line-height: 19px;"
            flexGrow={1}
            fontSize="20px"
            flexShrink={1}
            borderRight={isMobile ? null : `1px solid #bfc3db`}

        >
            {data.lead_name}
        </Flex>
    )
    const leadImageFragment = (
        <Flex
            width="44px"
            overflow="hidden"
            height="44px"
            borderRadius="100%"
            flexCenter
        >
            <img src={data.lead_photo_url || (dummyAvatar as unknown as string)} alt="avatar" />
        </Flex>
    )

    const leadPositionFragment = (
        <>
            <Flex
                alignItems="center"
                py="8px"
                flexGrow={1}
                css="& { white-space: nowrap; }"
                borderRight={`1px solid #bfc3db`}
                borderBottom={isMobile ? `1px solid #bfc3db` : null}
                textAlign="left"
                overflow="hidden"
            >
                {data.property}
            </Flex>
            <Flex
                alignItems="center"
                pr={'2px'}
                fontSize={isLongShiftTitle ? '14px' : 'inherit'}
                borderBottom={isMobile ? `1px solid #bfc3db` : null}
                borderRight={isMobile ? null : `1px solid #bfc3db`}
                textAlign={isMobile ? 'right' : 'left'}
                justifyContent={isMobile ? "flex-end" : 'flex-start'}
                css="& {text-transform: uppercase; letter-spacing: -0px; }"
            >
                {data.shift?.replace?.(/_/g, ' ') ?? 'N/A'}
            </Flex>
            <Flex
                py="4px"
                alignItems="center"
                borderRight={`1px solid #bfc3db`}
                flexGrow={1}
                textAlign="left"
                overflow="hidden"
                css="& { white-space: nowrap; }"
            >
                {data.position}
            </Flex>
            <Flex
                alignItems="center"
                justifyContent={isMobile ? "flex-end" : 'flex-start'}
                py="4px"
                pl={'2px'}
            >
                ${Number(data.pay_rate)}
            </Flex>
        </>
    )

    if (isMobile) {
        return (
            <Flex flexDirection={'column'} gap={'16px'} mb="10px">
                <Card css={`padding: 8px 20px; align-items: center;`}>
                    {leadNameFragment}
                    {leadImageFragment}
                </Card>
                <Card css={`padding: 4px 20px;`}>
                    <PositionGrid>
                        {leadPositionFragment}
                    </PositionGrid>
                </Card>
            </Flex>
        )
    } else {
        return (
            <Card css={`
                padding: 8px 20px; 
                align-items: center; 
                width: 100%; 
                max-width: 1170px; 
                margin-bottom: 30px;
                gap: 10px;
                display: grid;
                grid-template-columns: 44px 2fr 2fr 1fr 2fr 1fr;
            `}>
                {leadImageFragment}
                {leadNameFragment}
                {leadPositionFragment}
            </Card>
        )
    }
};

export default InterviewLeadInfo;
