import { PlayIcon } from '@/assets';
import { Flex } from '@keyslabor/shared/ui';
import { useTranslation } from 'react-i18next';

export const PreviewAnswerPopover = () => {
  const { t } = useTranslation();

  return (
    <Flex
      height="60px"
      width="260px"
      background="rgba(255,255,255,0.7)"
      borderRadius="100px"
      justifyContent="space-between"
      alignItems="center"
      px="8px"
    >
      <PlayIcon />
      <Flex
        fontSize="18px"
        fontWeight="400"
        color="white"
        fontFamily="Noto Sans"
        justifyContent="center"
        flex="1"
      >
        {t('preview_answer')}
      </Flex>
    </Flex>
  );
}
