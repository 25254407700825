import {Answer} from "@/api/typesApi.ts";
import { Flex, Grid } from "@keyslabor/shared/ui";
import {CollapsibleQuestionAnswer} from "./CollapsibleQuestionAnswer.tsx";
import VideoPlayer from "@/components/VideoPlayer";
import styled from "styled-components";

const maxVideoWidth = '300px';

export const VideoWrapper = styled(Flex)`
    align-self: center;
    padding: 8px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(20, 57, 128, 0.1);
    max-width: ${maxVideoWidth}; 
    min-height: 0;
`

export const QuestionWrapper = styled(Grid)`
    grid-template-rows: minmax(112px, max-content) 88px auto;
    grid-template-columns: 100%;
    gap: 10px;
    justify-items: center;
`

export const Question = ({
                      answer,
                      questionNumber,
                      totalQuestions
                  }: {
    answer: Answer;
    questionNumber: number;
    totalQuestions: number;
}) => {
    return (
        <QuestionWrapper>
            <Flex flexDirection="column" mt="0px">
                <Flex fontSize="20px" fontWeight="700" color="#143980" flexCenter>
                    {`Question ${questionNumber} out of ${totalQuestions}`}
                </Flex>
                <Flex
                    mt="5px"
                    backgroundColor="white"
                    borderRadius="10px"
                    boxShadow="0px 4px 4px 0px rgba(20, 57, 128, 0.1)"
                    p="7px 10px"
                    fontSize="14px"
                    width="335px"
                    color="black"
                    fontWeight="700"
                >
                    {answer.question_text}
                </Flex>
            </Flex>
            <Flex flexDirection="column" mt="0px" width="100%">
                <Flex fontSize="20px" fontWeight="700" color="#143980" flexCenter>
                    Answer
                </Flex>
                <CollapsibleQuestionAnswer mt="5px">
                    {answer.answer_transcription ?? 'No answer provided'}
                </CollapsibleQuestionAnswer>
            </Flex>
            <VideoWrapper>
                <VideoPlayer src={answer.video_processed_url} controls />
            </VideoWrapper>
        </QuestionWrapper>
    );
};
