import  { useState, useCallback, Dispatch, SetStateAction } from 'react';

export function safeJSONParse<T = unknown, K = null>(
  text: string,
  fallback: K = null as K
) {
  try {
    return JSON.parse(text) as T;
  } catch {
    return fallback;
  }
}

export enum SessionStorageKeys {
  SELECTED_LANGUAGE = 'selectedLanguage',
  CURRENT_QUESTION_NUMBER = 'currentQuestionNum',
  INVITATION_KEY = 'invitation_key',
}

export function setSessionStorageValue<T>(
  key: SessionStorageKeys,
  value: T
): void {
  // just fail hard if sessionStorage not available
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function unSessionStorageValue(key: SessionStorageKeys): void {
  // just fail hard if sessionStorage not available
  sessionStorage.removeItem(key);
}

export function getSessionStorageValue<T>(key: SessionStorageKeys): T | null {
  // just fail hard if sessionStorage not available
  const value = sessionStorage.getItem(key);
  if (value === null) {
    return null;
  }
  return safeJSONParse<T>(value);
}

export function useSessionStorage<T>(
  key: SessionStorageKeys,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>];
export function useSessionStorage<T>(
  key: SessionStorageKeys,
  defaultValue?: null
): [T | null, Dispatch<SetStateAction<T | null>>];
export function useSessionStorage<T>(
  key: SessionStorageKeys,
  defaultValue: T | null = null
): [T | null, (value: T) => void] {
  const [storedValue, _setStoredValue] = useState<T | null>(
    getSessionStorageValue<T>(key) ?? defaultValue
  );

  const setStoredValue = useCallback(
    (value: T) => {
      setSessionStorageValue(key, value);
      _setStoredValue(value);
    },
    [_setStoredValue, key]
  );

  return [storedValue, setStoredValue];
}
