/**
 *
 * @param url signed url to upload video
 * @param videoRecordDataWebm video blob to upload
 * @param timeout in seconds
 */
const uploadVideoAnswer = async (url: string, videoRecordDataWebm: Blob, timeout: number) => {
    const controller = new AbortController();

    const uploadPromise = fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'video/webm',
        },
        body: videoRecordDataWebm,
        signal: controller.signal,
    });

    const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
            reject(new Error('Upload timed out'));
            controller.abort();
        }, timeout * 1000);
    });

    return Promise.race([uploadPromise, timeoutPromise]);
};

export default uploadVideoAnswer;
