import 'styled-components';
import { Row, Text } from '@keyslabor/shared/ui';
import { FinishEnvelop, Logo } from '@/assets';
import LayoutPageMobile from '@/components/LayoutPageMobile';
import { useTranslation } from 'react-i18next';

function Finish() {
  const { t } = useTranslation();

  return (
    <LayoutPageMobile>
      <Row alignItems="flex-start" justifyItems="center" px="20px">
        <Logo />
        <Row flexCenter mt="70px">
          <Text fontWeight="900" mt="" fontSize="20px" upper>
            {t('finish.success')}
          </Text>
        </Row>
        <Row flexCenter mt="20px">
          <Text center fontWeight="900" fontSize="20px" upper maxWidth="300px">
            {t('finish.complete')}
          </Text>
        </Row>
        <Row flexCenter mt="40px">
          <FinishEnvelop />
        </Row>
        <Row flexCenter mt="40px">
          <Text center fontWeight="900" fontSize="18px" upper>
            {t('finish.review')}
          </Text>
        </Row>
        <Row flexCenter mt="40px">
          <Text center fontWeight="900" fontSize="20px" upper>
            {t('finish.thank_you')}
          </Text>
        </Row>
      </Row>
    </LayoutPageMobile>
  );
}

export default Finish;
