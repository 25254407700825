import React, {FC, PropsWithChildren} from 'react';
import styled from "styled-components";
import {Flex} from "@keyslabor/shared/ui";
import useInterviewPreviewScrollNavigation from "../useInterviewPreviewScrollNavigation.ts";
import {SlideWrapper} from './FullHeightWrapper';
import {SwipeDownButton} from "./SwipeButtons.tsx";


const InterviewList = styled(Flex)`
    flex-direction: column;
    gap: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    overflow: hidden;
  
    & > div {
        height: 100dvh;
        min-height: 100dvh;
        max-height: 100dvh;
    }
`;

const InterviewSlider: FC<PropsWithChildren> = ({children}) => {

    const childrenArray = React.Children.toArray(children);
    const { handleNextStep } = useInterviewPreviewScrollNavigation(childrenArray.length)
    const totalQuestions = childrenArray.length;

    const slides = childrenArray.map((child, index) => {
        const isLast = index === totalQuestions - 1;
        return (
            <SlideWrapper
                after={<SwipeDownButton isLast={isLast} questionNumber={index} onNextStep={handleNextStep} />}
                key={index}
            >
                {child}
            </SlideWrapper>
        )
    });
    return (
        <InterviewList>
            {slides}
        </InterviewList>
    );
};

export default InterviewSlider;
