import React, {FC} from 'react';
import arrowDown from "../assets/arrowDown.svg";
import {Flex} from "@keyslabor/shared/ui";
import {getNumberWithOrdinal} from "@/utils/format.ts";
import { useIsMobile } from '@keyslabor/shared/hooks/useBreakPoint.ts';

function getAnswerSubtitle(curAnswer: number) {
    if (curAnswer === 0) {
        return 'to watch full interview'
    }
    const formatAnswer = getNumberWithOrdinal(curAnswer);

    return `to ${formatAnswer} question`
}

export const SwipeUpButton: FC<{
    questionNumber: number;
    onClick?: () => void;
}> = ({ onClick, questionNumber }) => {
    const isMobile = useIsMobile();
    if (isMobile) {
        return null;
    }

    const arrow = <img width="24px" height="24px" className={'rotate-180'} src={arrowDown} alt="swipe up" />
    return (
        <Flex
            mt="0pt"
            minHeight={'42px'}
            justifyContent="space-around"
            alignItems="center"
            onClick={onClick}
            width="100%"
            flexDirection="row"
        >
            {arrow}
            <Flex
                fontSize="18px"
                fontWeight="600"
                color="#5B7188"
                flexDirection="column"
                alignItems="center"
            >
                <Flex mt="-5px">
                    {questionNumber === 1 ? 'to watch introduction' : getAnswerSubtitle(questionNumber - 1)}
                </Flex>
            </Flex>
            {arrow}
        </Flex>
    );
};

export const SwipeDownButton: FC<{
    isLast: boolean;
    questionNumber: number;
    onNextStep?: () => void;
}> = ({isLast,onNextStep, questionNumber}) => {
    const isMobile = useIsMobile();
    const arrow = <img width="24px" height="24px" src={arrowDown} alt="swipe down" />
    if (!isMobile) {
        return null;
    }

    return (
        <Flex
            mt="0pt"
            minHeight={'42px'}
            justifyContent="space-around"
            alignItems="center"
            onClick={onNextStep}
            width="100%"
            flexDirection="row"
        >
            {!isLast && arrow}
            <Flex
                fontSize="18px"
                fontWeight="600"
                color="#5B7188"
                flexDirection="column"
                alignItems="center"
            >
                {!isLast && isMobile && <Flex>Swipe down</Flex>}
                {!isLast && (
                    <Flex mt="-5px">
                        {getAnswerSubtitle(questionNumber + 1)}
                    </Flex>
                )}
                {isLast && <Flex>Done</Flex>}
            </Flex>
            {!isLast && arrow}
        </Flex>
    );
};
