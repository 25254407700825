import VideoPlayer from '@/components/VideoPlayer';
import { PreviewAnswerPopover } from './PreviewAnswerPopover';

interface VideoRecord {
  src?: string | null;
  width: number;
}

export default function VideoReplay({ src, width }: VideoRecord) {
  return (
    <VideoPlayer
      src={src ?? ''}
      width={width + 'px'}
      style={{
        position: 'relative',
        zIndex: 10,
      }}
      type="video/mp4"
      PlayIcon={<PreviewAnswerPopover />}
      ReplayIcon={<PreviewAnswerPopover />}
    ></VideoPlayer>
  );
}
