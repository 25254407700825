import 'styled-components';
import { Flex, Row } from '@keyslabor/shared/ui';
import {useCallback, useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import LayoutPageMobile from '@/components/LayoutPageMobile';
import RecordButton from './RecordButton';
import TopTextBlock from '@/components/TopTextBlock';
import {usePostAnswersConfirmation, usePostAnswersUploadRejected} from '@/api/services';
import { routes } from '@/constants';
import Preloader from '@/components/Preloader';
import { isDevPreview } from '@/utils';
import * as Sentry from '@sentry/react';
import useGetQuestionWithAnswered from '@/hooks/useGetQuestionWithAnswered';
import useCountdown from '@/hooks/useTimer';
import { VIDEO_MAX_LENGTH } from './constants';
import theme from '@keyslabor/shared/theme';
import VideoReplay from './VideoReplay';
import VideoRecord from './VideoRecord';
import { useTranslation } from 'react-i18next';
import retry from 'async-retry'
import {QuestionsAnswersInput} from "@/api/typesApi.ts";
import useUploadRetryModal from '@/components/useUploadRetryModal';
import uploadVideoAnswer from "@/pages/Answers/uploadVideoAnswer.ts";

const UPLOAD_RETRY_DELAY = 3000;
const UPLOAD_RETRY_ATTEMPTS = 3;
const UPLOAD_SUCCESS_REDIRECT_DELAY = 3000;
const UPLOAD_ABORT_TIMEOUT = 60; // seconds

const resolveFakeUpload = (retryAttempt: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (retryAttempt === 5) {
        resolve({
          ok: true,
          status: 200,
          text: () => Promise.resolve(''),
        })
      } else {
        reject(new Error('fail'))
      }
    }, 3000)
  })
}


export default function Answers() {
  const [showRetryConfirm, setShowRetryConfirm] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [retryModal, triggerRetryModal] = useUploadRetryModal({ uploadTimeout: UPLOAD_ABORT_TIMEOUT });

  const {
    questionName,
    invitationKey,
    isLoading,
    data,
    selectedLanguage,
    questionNumber,
    totalQuestions,
    questionTopics,
    onNextQuestion,
  } = useGetQuestionWithAnswered();

  const { mutateAsync: triggerAnswersConfirmation } = usePostAnswersConfirmation();
  const { mutateAsync: triggerAnswersUploadRejected } = usePostAnswersUploadRejected();
  const [isRecordUploading, setIsRecordUploading] = useState(false);

  const isLastQuestion = questionNumber >= totalQuestions - 1;
  const answerPresignedUrl = data?.answer_presigned_url;

  const isIntroQuestion = questionNumber === 0;

  const [recordReplaySrc, setRecordReplaySrc] = useState<string | null>(null);
  const breakpointSm = Number(theme.breakpoints.sm);
  const cameraWidth =
    window.innerWidth > breakpointSm ? breakpointSm : window.innerWidth;

  const timer = useCountdown({
    initTime: VIDEO_MAX_LENGTH,
    onTimeout: () => {
      handleStopRecord();
    },
  });

  const mediaRecordRef = useRef<MediaRecorder | null>(null);

  const [isRecording, setIsRecording] = useState(false);
  const [isRecordReady, setIsRecordReady] = useState(false);
  const [videoRecordDataWebm, setVideoRecordDataWebm] = useState<Blob>();

  const isShowRecord = isRecording || !isRecordReady;

  const handleStartRecord = useCallback(async () => {
    if (!mediaRecordRef.current) {
      alert('Video camera is not available. Please provide permissions');
      return null;
    }

    if (mediaRecordRef.current.state === 'inactive') {
      mediaRecordRef.current.start();
      setIsRecording(true);
      timer.reset();
      timer.startCountdown();
    }
  }, [timer]);

  const handleStopRecord = useCallback(() => {
    if (!mediaRecordRef.current) {
      return null;
    }
    mediaRecordRef.current.stop();
    setIsRecording(false);
    timer.stop();
  }, [timer]);

  /*useEffect(() => {
    triggerRetryModal({
      variant: 'processing',
      retryAttempt: 1,
    });
  }, []);*/

  const handleSubmitAnswerToPresignedUrl = async () => {
    if (!videoRecordDataWebm || !answerPresignedUrl) {
      console.error('No video record data or answer presigned url');
      Sentry.captureException(
          new Error('No video record data or answer presigned url')
      );

      return;
    }

    setIsRecordUploading(true);

    const answerConfirmationPayload = {
      invitation_key: invitationKey,
      question_num: questionNumber,
      language: selectedLanguage,
    } as QuestionsAnswersInput;

    let isUploadedFromFirstAttempt = true;

    try {

      await retry(async (bailCb: unknown, retryAttempt: number) => {
        if (retryAttempt > 1) {
          isUploadedFromFirstAttempt = false;
          triggerRetryModal({
            variant: 'processing',
            retryAttempt,
          });
        }

        const fetchPromise = isDevPreview ?
            resolveFakeUpload(retryAttempt) :
            uploadVideoAnswer(answerPresignedUrl, videoRecordDataWebm, UPLOAD_ABORT_TIMEOUT);

        const res = await fetchPromise.catch((e) => {
          console.error('Failed to upload video to presigned url', e);
          triggerRetryModal({
            variant: 'retry',
            retryAttempt,
          });
          Sentry.captureException(e);
          throw e;
        })

        console.log(res)

        if (!res.ok) {
          const err = new Error('Failed to upload video to presigned url');
          // @ts-expect-error: responseStatus is not defined in Error
          err.responseStatus = res.status;
          // @ts-expect-error: responseText is not defined in Error
          err.responseText = await res.text();
          throw err;
        }
      }, {
        retries: UPLOAD_RETRY_ATTEMPTS,
        factor: 1,
        minTimeout: UPLOAD_RETRY_DELAY,
      })

      if (!isUploadedFromFirstAttempt) {
        await new Promise((resolve) => setTimeout(resolve, UPLOAD_SUCCESS_REDIRECT_DELAY));
      }

      triggerRetryModal((prevState) => {
        return prevState ? ({
          variant: 'success',
          retryAttempt: 0,
        }) : null
      });

      await triggerAnswersConfirmation(answerConfirmationPayload).catch((e) => {
        console.error('Failed to post answer confirmation ', e);
        Sentry.captureException(e);
      });

      return true;
    } catch (e) {
      triggerRetryModal((prevState) => {
        return prevState ? ({
          variant: 'error',
          retryAttempt: 0,
        }) : null
      });
      triggerAnswersUploadRejected(answerConfirmationPayload).catch(() => {});
      // alert('Server connection error. Please try again later');
      console.error('Failed to upload video to presigned url', e);
      Sentry.withScope((scope) => {
        scope.setContext('submissionDetails', {
          invitationKey,
          selectedLanguage,
          answerPresignedUrl,
          questionNumber,
          // @ts-expect-error: videoPutStatusCode is not defined in Error
          videoPutStatusCode: e.responseStatus,
          // @ts-expect-error: videoPutText is not defined in Error
          videoPutText: e.responseText,
        });
        Sentry.captureException(e);
      });
    } finally {
      setIsRecordUploading(false);
    }

    return false;
  };

  const handleNextStep = async () => {
    if (!isRecordReady) {
      return;
    }

    const isSuccess = await handleSubmitAnswerToPresignedUrl()
    if (!isSuccess)
      return

    if (isLastQuestion) {
      navigate(`${routes.finish}?turn_off_handle_routing=1`);
      return;
    }

    onNextQuestion(questionNumber + 1);
    navigate(routes.questions);
  };

  return (
    <LayoutPageMobile
      header={
        <Row flexCenter>
          {isIntroQuestion ? (
            <TopTextBlock
              title={t('answers.topics')}
              content={questionTopics}
            />
          ) : (
            <TopTextBlock
              title={`${t('answers.topics_for_question')} #${questionName}`}
              content={questionTopics}
            />
          )}
        </Row>
      }
      loading={isLoading}
    >
      <Row
        flexCenter
        position="relative"
        flexGrow={1}
        zIndex="50"
        height="100%"
        alignContent="flex-end"
      >
        {!isShowRecord ? (
          <VideoReplay src={recordReplaySrc} width={cameraWidth} />
        ) : (
          <VideoRecord
            cameraWidth={cameraWidth}
            mediaRecordRef={mediaRecordRef}
            setIsRecordReady={setIsRecordReady}
            setRecordReplaySrc={setRecordReplaySrc}
            setVideoRecordDataWebm={setVideoRecordDataWebm}
          />
        )}
      </Row>
      <Row flexCenter>
        <RecordButton
          onShowRetryConfirm={() => setShowRetryConfirm(true)}
          isShowRetryConfirm={showRetryConfirm}
          isRecordReady={isRecordReady}
          isRecording={isRecording}
          onNextStep={handleNextStep}
          onRecordStart={handleStartRecord}
          onRecordStop={handleStopRecord}
          onRetryRecord={() => {
            setIsRecordReady(false);
          }}
          onHideRetryConfirm={() => setShowRetryConfirm(false)}
          timeLeft={!isRecordReady ? timer.time : 0}
        >
          {isRecordUploading && (
            <Flex gap="10px" flexCenter>
              <span>Uploading...</span> <Preloader size="25px" />
            </Flex>
          )}
          {!isRecordUploading && !isLastQuestion && t('answers.submit_and_finish')}
          {!isRecordUploading && isLastQuestion && t('answers.submit_and_continue')}
        </RecordButton>
      </Row>
      {retryModal}
    </LayoutPageMobile>
  );
}
