import i18n from "i18next";
import {  initReactI18next } from "react-i18next";
import englishJSON from './locales/en.json'
import spanishJSON from './locales/sp.json'

i18n.use(initReactI18next).init({
  resources: {
    english: { ...englishJSON },
    spanish: { ...spanishJSON },
  },
  lng: "english",
});
