import { useQuestionsAnswers } from '@/hooks/useQuestionsAnswers';
import theme from '@keyslabor/shared/theme';
import { Box } from '@keyslabor/shared/ui';

interface DashProps {
  active?: boolean;
}

export function Dash({ active }: DashProps) {
  return (
    <Box
      width="50px"
      height="6px"
      borderRadius="10px"
      backgroundColor={active ? theme.colors.blueDeeeeep : theme.colors.blueWtf}
    ></Box>
  );
}

export interface TopNavDashesProps {
  totalDashes?: number;
  activeDashes?: number;
}

export default function TopNavDashes() {
  const { currentQuestionNumber, langContext } = useQuestionsAnswers();

  return (
    <Box gap="6px" dxFlex flexDirection="row" justifyContent="center">
      {Array.from({ length: langContext.questionsTotal || 0 }, (_, i) => (
        <Dash key={i} active={i <= currentQuestionNumber} />
      ))}
    </Box>
  );
}
