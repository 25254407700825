import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import theme, { antdColorsConfig, antdTypographyConfig } from '../theme';
import { ReactNode } from 'react';

export default function AppContext({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            ...antdColorsConfig,
            ...antdTypographyConfig,
          },
        }}
      >
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ConfigProvider>
    </>
  );
}
