import { css } from 'styled-components';
import colors from '@keyslabor/shared/theme/colors';
import { Row, Box, Button, Flex } from '@keyslabor/shared/ui';
import TryAgainPopup from './TryAgainPopup';
import theme from '@keyslabor/shared/theme';
import { useTranslation } from 'react-i18next';

interface RecordButtonProps {
  isRecording: boolean;
  isRecordReady: boolean;
  onNextStep: () => void;
  onRecordStart: () => void;
  onRecordStop: () => void;
  onHideRetryConfirm: () => void;
  onShowRetryConfirm: () => void;
  isShowRetryConfirm: boolean;
  timeLeft: number;
  children?: React.ReactNode;
  onRetryRecord?: () => void;
}

export default function RecordButton({
  isRecordReady,
  isRecording,
  onNextStep,
  onRecordStart,
  onRecordStop,
  isShowRetryConfirm,
  onShowRetryConfirm,
  onHideRetryConfirm,
  timeLeft,
  children,
  onRetryRecord,
}: RecordButtonProps) {
  const isTryAgain = !isRecording && isRecordReady;
  const { t } = useTranslation();

  return (
    <>
      <Row
        mt="20px"
        width="100%"
        position="absolute"
        backgroundColor="transparent"
        flexCenter
        height="140px"
        borderTopLeftRadius="20px"
        borderTopRightRadius="20px"
        bottom="0"
        zIndex={100}
      >
        <Box
          position="absolute"
          top="-25px"
          borderRadius="100%"
          size="90px"
          borderWidth="4px"
          borderStyle="solid"
          borderColor="white"
          flexCenter
          style={{
            cursor: 'pointer',
            ...(isRecording && {
              backgroundColor: colors.redCrimson,
            }),
            ...(!isRecording && {
              backgroundColor: colors.greenSummer,
            }),
            ...(isTryAgain && {
              backgroundColor: colors.blueDeep,
            }),
          }}
        >
          <Box
            textAlign="center"
            fontWeight="700"
            color="white"
            fontSize="16px"
            margin="0 auto"
            height="100%"
            css={css`
              & {
                display: -webkit-flex;
                display: flex;
                justify-content: center;
                -webkit-justify-content: center;
                align-items: center;
                -webkit-align-items: center;
              }
            `}
            {...{
              ...(isRecording && {
                onClick: onRecordStop,
              }),
              ...(!isRecording && {
                onClick: onRecordStart,
              }),
              ...(isTryAgain && {
                onClick: () => {
                  onShowRetryConfirm();
                },
              }),
            }}
          >
            <span
              key={timeLeft}
              className="buttonTextWrapper"
              style={{
                whiteSpace: 'wrap',
                padding: '10px',
                textAlign: 'center',
              }}
            >
              {isRecording && (
                <span key={timeLeft}>
                  {t('record_button.stop')}
                  <br />
                  <span>
                    {timeLeft} {t('record_button.sec')}
                  </span>
                </span>
              )}
              {!isRecordReady && !isRecording && (
                <>{t("record_button.start_record")}</>
              )}
              {isRecordReady && !isRecording && (
                <Flex gap="0" flexDirection="column">
                  <span style={{height: '16px'}}>
                    {t("record_button.try_again_ln1")}
                  </span>
                  <span>{t('record_button.try_again_ln2')}</span>
                </Flex>
              )}
            </span>
          </Box>
        </Box>
        <Button
          mt="60px"
          maxWidth="332px"
          kind="large"
          disabled={!isRecordReady}
          onClick={onNextStep}
          css={
            !isRecordReady
              ? `& { background-color: ${theme.colors.grayDisabled} !important; }`
              : ''
          }
        >
          {children}
        </Button>
      </Row>
      <TryAgainPopup
        onHideRetryConfirm={onHideRetryConfirm}
        isShowRetryConfirm={isShowRetryConfirm}
        onRecordStart={() => {
          onRetryRecord?.();
        }}
      />
    </>
  );
}
