import 'styled-components';
import { Row } from '@keyslabor/shared/ui';
import VideoPlayer from '@/components/VideoPlayer';
import useVideoControl from '@/hooks/useVideoControl';
import ButtonFillEffect from '@/components/ButtonFillEffect';
import LayoutPageMobile from '@/components/LayoutPageMobile';
import TopTextBlock from '@/components/TopTextBlock';
import { useTranslation } from 'react-i18next';
import {isDevPreview} from "@/utils";

interface IntroductionProps {
  onNextStepClick?: () => void;
  isLoading: boolean;
  questionVideo?: string;
  isError?: boolean;
  error?: Error | string;
}

export default function Introduction({
  error,
  isError,
  onNextStepClick,
  isLoading,
  questionVideo,
}: IntroductionProps) {
  const { setCurRef, isPlaying, progress, isWatched } = useVideoControl();
  const { t } = useTranslation();
  const nextStepHandler = (isDevPreview || isWatched) ? onNextStepClick : undefined;

  return (
    <LayoutPageMobile
      loading={isLoading}
      error={!!isError}
      errorInfo={(error as Error)?.message ?? error}
      header={
        <Row flexCenter>
          <TopTextBlock title={t('questions.introduction')} />
        </Row>
      }
      footer={
        <Row
          py="20px"
          borderTopLeftRadius="4px"
          borderTopRightRadius="4px"
          width="100%"
          flexCenter
          backgroundColor="white"
        >
          <ButtonFillEffect
            onClick={nextStepHandler}
            isPlaying={isPlaying}
            progress={progress}
          >
            {t('questions.begin_introduction')}
          </ButtonFillEffect>
        </Row>
      }
    >
      <Row flexCenter>
        <Row mt="20px">
          <VideoPlayer autoPlay src={questionVideo} onSetCurRef={setCurRef} />
        </Row>
      </Row>
    </LayoutPageMobile>
  );
}
