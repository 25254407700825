import {
  css,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
} from 'styled-components';
import {
  compose,
  typography,
  background,
  color,
  space,
  layout,
} from 'styled-system';
import { flexCenter } from '../layout';
import { TextBaseProps } from './Text.types';

export const basic = () => css`
  transition: hover 0.3s ease;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
`;

export const kindIcon = ({ kind }: Pick<TextBaseProps, 'kind'>) =>
  kind === 'icon' &&
  css`
    display: flex;
    cursor: pointer;
    &:hover {
      filter: brightness(85%);
    }
    ${flexCenter};
  `;
export const styledSystem = compose(
  background,
  space,
  color,
  typography,
  layout
);
