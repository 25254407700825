import * as React from "react";
const SvgRetryLogoAnimated = (props) => /* @__PURE__ */ React.createElement("svg", { width: 80, height: 80, viewBox: "0 0 80 80", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M39.9664 14.9261C44.0881 14.9261 47.4293 11.5848 47.4293 7.46304C47.4293 3.34132 44.0881 0 39.9664 0C35.8447 0 32.5034 3.34132 32.5034 7.46304C32.5034 11.5848 35.8447 14.9261 39.9664 14.9261Z", fill: "#A3A8CC", className: "animate-pulse-logo", style: {
  "--animation-delay": "0ms"
} }), /* @__PURE__ */ React.createElement("path", { d: "M30.1593 26.953C31.2695 28.0015 33.4282 28.7416 34.6001 28.7416C37.2522 28.8033 42.6798 28.8033 45.3319 28.7416C46.7505 28.7416 48.4775 28.2482 49.7727 26.953C52.1781 24.6092 59.271 17.4545 59.271 17.4545C61.0597 15.6659 61.4297 12.767 59.9495 10.67C58.0375 7.95612 54.2135 7.77109 51.9931 9.9915C51.9931 9.9915 46.3188 17.0228 43.6666 18.318C42.0014 19.1198 37.9306 19.0582 36.2037 18.318C33.4899 17.2078 27.9389 10.0532 27.9389 10.0532C26.1503 8.26451 23.2514 7.89445 21.1544 9.37472C18.4406 11.2867 18.2556 15.1108 20.476 17.3312C20.5376 17.3312 27.6305 24.5475 30.1593 26.953Z", fill: "#A3A8CC", className: "animate-pulse-logo", style: {
  "--animation-delay": "0ms"
} }), /* @__PURE__ */ React.createElement("path", { d: "M72.5369 47.4302C76.6586 47.4302 79.9999 44.0889 79.9999 39.9672C79.9999 35.8455 76.6586 32.5042 72.5369 32.5042C68.4153 32.5042 65.074 35.8455 65.074 39.9672C65.074 44.0889 68.4153 47.4302 72.5369 47.4302Z", fill: "#A3A8CC", className: "animate-pulse-logo", style: {
  "--animation-delay": "500ms"
} }), /* @__PURE__ */ React.createElement("path", { d: "M53.0466 49.8356C51.9981 48.7254 51.2579 46.5667 51.2579 45.3948C51.1962 42.7426 51.1962 37.315 51.2579 34.6628C51.2579 33.2442 51.7513 31.5172 53.0466 30.222C55.3903 27.8166 62.5449 20.7236 62.5449 20.7236C64.3335 18.9349 67.2324 18.5649 69.3294 20.0451C72.0432 21.9571 72.2282 25.7812 70.0078 28.0016C70.0078 28.0016 62.9766 33.676 61.6814 36.3281C60.8796 37.9934 60.9413 42.0642 61.6814 43.7912C62.7916 46.505 69.9462 52.056 69.9462 52.056C71.7348 53.8447 72.1049 56.7436 70.6246 58.8406C68.7126 61.5544 64.8886 61.7395 62.6682 59.5191C62.6066 59.3957 55.3903 52.3644 53.0466 49.8356Z", fill: "#A3A8CC", className: "animate-pulse-logo", style: {
  "--animation-delay": "500ms"
} }), /* @__PURE__ */ React.createElement("path", { d: "M39.9661 79.9961C44.0878 79.9961 47.4291 76.6548 47.4291 72.5331C47.4291 68.4114 44.0878 65.0701 39.9661 65.0701C35.8445 65.0701 32.5032 68.4114 32.5032 72.5331C32.5032 76.6548 35.8445 79.9961 39.9661 79.9961Z", fill: "#A3A8CC", className: "animate-pulse-logo", style: {
  "--animation-delay": "1000ms"
} }), /* @__PURE__ */ React.createElement("path", { d: "M30.1593 53.0429C31.2695 51.9944 33.4282 51.2543 34.6001 51.2543C37.2522 51.1926 42.6798 51.1926 45.3319 51.2543C46.7505 51.2543 48.4775 51.7477 49.7727 53.0429C52.1781 55.3867 59.271 62.5413 59.271 62.5413C61.0597 64.33 61.4297 67.2289 59.9495 69.3259C58.0375 72.0398 54.2135 72.2248 51.9931 70.0044C51.9931 70.0044 46.3188 62.9731 43.6666 61.6779C42.0014 60.876 37.9306 60.9377 36.2037 61.6779C33.4899 62.7881 27.9389 69.9427 27.9389 69.9427C26.1503 71.7314 23.2514 72.1014 21.1544 70.6212C18.4406 68.7091 18.2556 64.8851 20.476 62.6647C20.5376 62.603 27.6305 55.3867 30.1593 53.0429Z", fill: "#A3A8CC", className: "animate-pulse-logo", style: {
  "--animation-delay": "1000ms"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.46296 47.4295C11.5846 47.4295 14.9259 44.0882 14.9259 39.9665C14.9259 35.8447 11.5846 32.5034 7.46296 32.5034C3.34128 32.5034 0 35.8447 0 39.9665C0 44.0882 3.34128 47.4295 7.46296 47.4295Z", fill: "#A3A8CC", className: "animate-pulse-logo", style: {
  "--animation-delay": "1500ms"
} }), /* @__PURE__ */ React.createElement("path", { d: "M26.9535 49.8353C28.002 48.7251 28.7421 46.5663 28.7421 45.3945C28.8038 42.7423 28.8038 37.3146 28.7421 34.6625C28.7421 33.2439 28.2487 31.5169 26.9535 30.2217C24.6097 27.7545 17.4552 20.6616 17.4552 20.6616C15.6665 18.8729 12.7677 18.5028 10.6707 19.9831C7.95686 21.8951 7.77183 25.7192 9.99222 27.9396C9.99222 27.9396 17.0234 33.614 18.3187 36.2661C19.1205 37.9314 19.0588 42.0022 18.3187 43.7292C17.2085 46.443 10.0539 51.994 10.0539 51.994C8.26525 53.7827 7.89519 56.6815 9.37544 58.7786C11.2874 61.4924 15.1114 61.6775 17.3318 59.4571C17.3318 59.3954 24.5481 52.3641 26.9535 49.8353Z", fill: "#A3A8CC", className: "animate-pulse-logo", style: {
  "--animation-delay": "1500ms"
} }));
export default SvgRetryLogoAnimated;
