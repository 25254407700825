import React, {FC, PropsWithChildren, useState} from 'react';
import {Grid, Flex} from "@keyslabor/shared/ui";
import styled from "styled-components";
import {SlideWrapper} from "./FullHeightWrapper.tsx";
import {Pagination} from 'antd';
import {useWindowSize} from 'usehooks-ts'

type InterviewCarouselProps = PropsWithChildren<{
    width?: number;
}>;

const sliceChunks = (children: React.ReactNode[], chunkSize: number = 3) => {
    const chunks: React.ReactNode[][] = [];
    for (let i = 0; i < children.length; i += chunkSize) {
        chunks.push(children.slice(i, i + chunkSize));
    }
    return chunks
}

const CarouselItem = styled(Flex)`
    gap: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

const getItemsOnPage = (width: number) => {

    if (!width || width < 600) {
        return 1;
    } else if (width < 900) {
        return 2;
    } else {
        return Math.min(4, Math.floor(width / 400));
    }
}

const InterviewCarousel: FC<InterviewCarouselProps> = ({ children }) => {
    const { width } = useWindowSize()
    const [currentPage, setCurrentPage] = useState(1)
    const itemsOnPage = getItemsOnPage(width);

    const childrenArray = React.Children.toArray(children);
    const chunks = sliceChunks(childrenArray, itemsOnPage)

    const chunk = chunks[currentPage - 1]

    const handlePageChange = (page: number) => {
        setCurrentPage(page)
    }

    return (
        <Grid css={`min-height: 0; grid-template-rows: auto 72px; align-items: center; justify-items: center; flex-grow: 1;`}>
            <CarouselItem>
                {chunk.map((child, index) => (
                    <SlideWrapper key={index}>
                        {child}
                    </SlideWrapper>
                ))}
            </CarouselItem>
            <Pagination
                defaultCurrent={currentPage}
                pageSize={itemsOnPage}
                total={childrenArray.length}
                onChange={handlePageChange}
            />
        </Grid>
    );
};

export default InterviewCarousel;
