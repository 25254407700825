import { useCallback, useEffect } from 'react';
import { Language } from '@/api/typesApi';
import { useSearchParams } from 'react-router-dom';
import { useGetStatus } from '@/api/services';
import { useTranslation } from 'react-i18next';

import { useSessionStorage, SessionStorageKeys } from '@/utils/storage';

const DEFAULT_LANGUAGE = 'english';

export const useQuestionsAnswers = () => {
  const [language, _setLanguage] = useSessionStorage<Language>(
    SessionStorageKeys.SELECTED_LANGUAGE,
    DEFAULT_LANGUAGE
  );
  const { i18n: {changeLanguage} } = useTranslation();
  const [currentQuestionNumber, setCurrentQuestionNumber] =
    useSessionStorage<number>(SessionStorageKeys.CURRENT_QUESTION_NUMBER, 0);
  const [invitationKey, _setInvitationKey] = useSessionStorage<string>(
    SessionStorageKeys.INVITATION_KEY
  );

  useEffect(() => {
    changeLanguage(language);
  }, []);

  const setLanguage = useCallback((value) => {
    _setLanguage(value);
    changeLanguage(value);
  }, [_setLanguage]);

  const [params] = useSearchParams();
  const paramsInvitationKey = params.get(SessionStorageKeys.INVITATION_KEY);

  const setInvitationKey = useCallback(
    (invitationKey) => {
      _setInvitationKey(invitationKey);
      _setLanguage(DEFAULT_LANGUAGE);
      setCurrentQuestionNumber(0);
    },
    [_setInvitationKey, _setLanguage, setCurrentQuestionNumber]
  );

  const { data, isError, error, isFetching, isLoading } =
    useGetStatus(invitationKey);

  useEffect(() => {
    if (paramsInvitationKey && paramsInvitationKey !== invitationKey) {
      setInvitationKey(paramsInvitationKey);
    }
  }, [invitationKey, paramsInvitationKey, setInvitationKey]);

  const isEngSelected = language === DEFAULT_LANGUAGE;
  const questionsTotal = (isEngSelected
    ? data?.questions_total_english
    : data?.questions_total_spanish) ?? 0
  return {
    invitationKey,
    remoteStatus: data,
    localeData: {
      introVideoSrc: isEngSelected ? data?.english_intro : data?.spanish_intro,
      questionsTotal,
      estimatedTime: questionsTotal * 2,
    },
    language,
    currentQuestionNumber: currentQuestionNumber,
    langContext: {
      language,
      intro: data?.[`${language}_intro`],
      questionsTotal: data?.[`questions_total_${language}`],
    },
    isLoading: isLoading && isFetching, // isFetching is used considering that we are using conditional fetch
    isError,
    error,
    setLanguage,
    setCurrentQuestionNumber,
    setInvitationKey,

  };
};
