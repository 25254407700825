import 'styled-components';
import {Row} from '@keyslabor/shared/ui';
import VideoPlayer from '@/components/VideoPlayer';
import useVideoControl from '@/hooks/useVideoControl';
import ButtonFillEffect from '@/components/ButtonFillEffect';
import LayoutPageMobile from '@/components/LayoutPageMobile';
import TopTextBlock from '@/components/TopTextBlock';
import {isDevPreview} from '@/utils';
import useGetQuestionWithAnswered from '@/hooks/useGetQuestionWithAnswered';
import Introduction from './Introduction';
import {useTranslation} from 'react-i18next';
import {FC} from 'react';

interface IntroductionProps {
    onNextStepClick?: () => void;
    isLoading: boolean;
    questionVideo?: string;
    questionName?: string;
    questionText?: string;
}

const InterviewQuestion: FC<IntroductionProps> = ({
                                                      onNextStepClick,
                                                      isLoading,
                                                      questionVideo,
                                                      questionName,
                                                      questionText,
                                                  }) => {
    const {setCurRef, isWatched, isPlaying, progress} = useVideoControl();
    const {t} = useTranslation();
    const nextStepHandler = (isDevPreview || isWatched) ? onNextStepClick : undefined;

    return (
        <LayoutPageMobile
            loading={isLoading}
            header={
                <Row flexCenter>
                    <TopTextBlock
                        title={t('questions.title') + questionName}
                        content={questionText?.split(', ') || []}
                    />
                </Row>
            }
            footer={
                <Row
                    py="20px"
                    borderTopLeftRadius="4px"
                    borderTopRightRadius="4px"
                    width="100%"
                    flexCenter
                    backgroundColor="white"
                >
                    <ButtonFillEffect onClick={nextStepHandler} progress={progress} isPlaying={isPlaying}>
                        {t('questions.answer_question')}
                    </ButtonFillEffect>
                </Row>
            }
        >
            <Row flexCenter>
                <Row mt="20px">
                    <VideoPlayer autoPlay src={questionVideo} onSetCurRef={setCurRef}/>
                </Row>
            </Row>
        </LayoutPageMobile>
    );
}

function Questions() {

    const {
        questionName,
        questionText,
        questionVideo,
        isLoading,
        handleNextStep,
        questionNumber,
    } = useGetQuestionWithAnswered();


    if (questionNumber === 0) {
        return (
            <Introduction
                onNextStepClick={handleNextStep}
                isLoading={isLoading}
                questionVideo={questionVideo}
            />
        );
    }

    return (
        <InterviewQuestion
            onNextStepClick={handleNextStep}
            isLoading={isLoading}
            questionVideo={questionVideo}
            questionName={questionName}
            questionText={questionText}
        />
    )

}

export default Questions;
