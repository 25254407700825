interface Props {
  success?: boolean;
  onSuccessOk?(): void;
  setSuccess?(p: boolean): void;
  onClose?(): void;
}

export default function useConfirmClose({
  setSuccess = () => null,
  onSuccessOk = () => null,
  success,
  onClose = () => null,
}: Props) {
  return () => {
    if (success && onSuccessOk) {
      onSuccessOk();
      onClose();
      return;
    }
    onClose();
  };
}
