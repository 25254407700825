import { RowProps } from 'antd';
import { css } from 'styled-components';
import { Flex, Text } from '@keyslabor/shared/ui';
import TopNavDashes from '../TopNavDashes';

interface TopTextBlockProps extends Omit<RowProps, 'content'> {
  title: string;
  content?: string[];
}

export default function TopTextBlock({
  title,
  content,
  ...restProps
}: TopTextBlockProps) {
  return (
    <Flex
      position="relative"
      zIndex="100"
      flexDirection="column"
      gap="20px"
      maxWidth="336px"
      width="100%"
      {...restProps}
    >
      <TopNavDashes />
      <Flex
        flexDirection="column"
        backgroundColor="white"
        padding="12px 28px 16px"
        width="100%"
        boxShadow="0px 4px 4px 0px #3B3D5A40"
        borderRadius="10px"
      >
        <Text fontSize={'18px'} fontWeight="700" textAlign="center">
          {title}
        </Text>
        {content && (
          <Flex
            mt="7px"
            css={css`
              & {
                ul {
                  font-size: 14px;
                  list-style-type: disc;
                  list-style-position: outside;
                  line-height: 15px;
                  font-weight: 700;
                  word-break: break-word;
                  list-style-image: url('data:image/svg+xml,<svg transform="scale(1.9), translate(0 -0.5)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M7.8 10a2.2 2.2 0 0 0 4.4 0 2.2 2.2 0 0 0-4.4 0z"></path></g></svg>');
                }
                li::marker {
                }
              }
            `}
          >
            <ul>
              {content?.map((text, index) => (
                <li key={`${index}-${text}`}>{text}</li>
              ))}
            </ul>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
