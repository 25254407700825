import Box, { BoxProps } from './Box';
import React from 'react';

type DividerType = 'horizontal' | 'vertical';

interface Props extends BoxProps {
  type?: DividerType;
}

export function Divider({ type = 'horizontal', ...restProps }: Props) {
  return type === 'vertical' ? (
    <Box width="2px" height="100%" bg="grayLighter" {...restProps} />
  ) : (
    <Box width="100%" height="2px" bg="grayLighter" {...restProps} />
  );
}
