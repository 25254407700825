import {useCallback, useEffect, useState} from "react";

const useInterviewPreviewScrollNavigation = (totalAnswers: number) => {
    const [touchMoveDelta, setTouchMoveDelta] = useState(0);
    const [touchStartPoint, setTouchStartPoint] = useState(0);

    const [curAnswer, setCurAnswer] = useState(0);

    const handleNextStep = useCallback(() => {
        const answersTopsEls = document.querySelectorAll('.answer-top');
        const videoElems = document.querySelectorAll('video');

        const nextAnswer = curAnswer + 1;
        const totalQuestion = totalAnswers;
        if (!answersTopsEls || totalQuestion === undefined) {
            return;
        }

        if (nextAnswer < totalQuestion) {
            answersTopsEls[nextAnswer].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            videoElems.forEach((video) => {
                video.pause();
            });
            videoElems[nextAnswer].play();
            setCurAnswer(nextAnswer);
        }
    }, [curAnswer, totalAnswers]);

    const handlePrevStep = useCallback(() => {
        const answersTopsEls = document.querySelectorAll('.answer-top');
        const videoElems = document.querySelectorAll('video');

        const prevAnswer = curAnswer - 1;
        if (prevAnswer >= 0) {
            answersTopsEls[prevAnswer].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            setCurAnswer(prevAnswer);
            videoElems.forEach((video) => {
                video.pause();
            });
            videoElems[prevAnswer].play();
        }
    },[curAnswer]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const answersTopsEls = document.querySelectorAll('.answer-top');
        const videoElems = document.querySelectorAll('video');

        const handleTouchStart = (e) => {
            // e.preventDefault();
            setTouchStartPoint(e.touches[0].clientY);
        };

        const handleTouchMove = (e) => {
            // e.preventDefault();
            setTouchMoveDelta(e.touches[0].clientY - touchStartPoint);
        };

        const handleTouchEnd = () => {
            if (touchMoveDelta < 0) {
                handleNextStep();
            }

            if (touchMoveDelta > 0) {
                const prevAnswer = curAnswer - 1;
                if (prevAnswer >= 0) {
                    answersTopsEls[prevAnswer].scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                    setCurAnswer(prevAnswer);
                    videoElems.forEach((video) => {
                        video.pause();
                    });
                    videoElems[prevAnswer].play();
                }
            }

            setTouchMoveDelta(0);
            setTouchStartPoint(0);
        };

        document.addEventListener('touchstart', handleTouchStart, {
            passive: false,
        });
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
        document.addEventListener('touchend', handleTouchEnd, { passive: false });

        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, [curAnswer, handleNextStep, touchMoveDelta, touchStartPoint]);

    return {
        handlePrevStep,
        handleNextStep
    }
}

export default useInterviewPreviewScrollNavigation
