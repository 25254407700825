import defaultTheme from './theme/theme.ts';

export function px(value?: number | string) {
  if (value === undefined) return 'unset';
  return typeof value === 'number' ? `${value}px` : value;
}

export const convertThemeColorNamesToHash = (
  colors: Record<string, any>,
  theme: typeof defaultTheme
) => {
  const convertedColors: Record<string, any> = {};
  for (const color in colors) {
    convertedColors[color] =
      colors[color] in theme['colors']
        ? theme.colors[colors[color]]
        : colors[color];
  }

  return convertedColors;
};



export function parseNumericWithDecimal(str: string) {
  if (str === '.' || str === ',') return '';
  return str
    .replace(/,/g, '.')
    .replace(/[^.\d]/g, '')
    .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');
}

export function parseNumeric(str: string) {
  if (str === '.') return '';
  return str.replace(/\D/g, '');
}


export const breakpoints = {
  0: 'xs',
  600: 'sm',
  960: 'md',
  1280: 'lg',
  1920: 'xl',
};

export enum BreakpointsEnum {
  Xs = 'xs',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xm',
}

export function getBrowserWidth() {
  return window.screen.width < window.innerWidth
    ? window.screen.width
    : window.innerWidth;
}

export function getBrowserHeight() {
  return window.screen.height < window.innerHeight
    ? window.screen.height
    : window.innerHeight;
}
