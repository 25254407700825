import 'styled-components';
import { Box, Flex, Row, Text } from '@keyslabor/shared/ui';
import { default as WatchSvg } from './assets/watch.svg?react';
import { useNavigate } from 'react-router-dom';
import useVideoControl from '@/hooks/useVideoControl';
import { routes } from '@/constants';
import VideoPlayer from '@/components/VideoPlayer';
import ButtonFillEffect from '@/components/ButtonFillEffect';
import LayoutPageMobile from '@/components/LayoutPageMobile';
import { useQuestionsAnswers } from '@/hooks/useQuestionsAnswers';
import { css } from 'styled-components';
import { isDevPreview } from '@/utils';
import { useTranslation } from 'react-i18next';

export default function Tutorial() {
  const { isWatched, setCurRef, isPlaying, progress } = useVideoControl();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { setCurrentQuestionNumber, remoteStatus: data, localeData, isLoading } =
    useQuestionsAnswers();
  const name = data?.lead_name;

  const handleNextStep = (isDevPreview || isWatched)  ? () => {
    setCurrentQuestionNumber(0);
    navigate(routes.questions);
  } : undefined;

  return (
    <LayoutPageMobile
      loading={isLoading}
      header={
        <Box dxFlex flexDirection={'column'} alignItems="center">
          <Text fontWeight="900" fontSize="18px" upper>
            {t('tutorial.hello')}
          </Text>
          <Text fontWeight="900" fontSize="24px" upper>
            {name}!
          </Text>
        </Box>
      }
      footer={
        <Row
          py="20px"
          borderTopLeftRadius="4px"
          borderTopRightRadius="4px"
          width="100%"
          flexCenter
          backgroundColor="transparent"
        >
          <ButtonFillEffect
            onClick={handleNextStep}
            isPlaying={isPlaying}
            progress={progress}
          >
            {t('tutorial.start_interview')}
          </ButtonFillEffect>
        </Row>
      }
    >
      <Row flexCenter>
        <Row>
          <Flex
            backgroundColor={'#FFFFFF'}
            color={'#EB5757'}
            width="330px"
            height={"74px"}
            borderRadius="4px"
            flexDirection="column"
            justifyContent={"center"}
            padding="10px 20px"
            fontWeight="900"
            css={css`
              & {
                text-transform: uppercase;
              }
            `}
          >
            <Flex justifyContent="center" alignItems="center" gap="20px">
              <Flex>
                <WatchSvg />
              </Flex>
              <Flex css={css`
                  font-size: 18px;
                  line-height: 22px;
                  letter-spacing: -1px;
                  text-transform: uppercase;
                `}
              >
                {t('tutorial.watch_video')}
              </Flex>
            </Flex>
          </Flex>
        </Row>
        <Row mt="20px">
          <VideoPlayer autoPlay onSetCurRef={setCurRef} src={localeData.introVideoSrc} />
        </Row>
        <Row mt="20px">
          <Flex
            backgroundColor={'#FFFFFF'}
            width="330px"
            borderRadius="4px"
            flexDirection="column"
            padding="10px 20px"
            fontSize="18px"
            fontWeight="700"
            css={css`text-transform: uppercase;`}
          >
            <Flex>
              {`${t('tutorial.total_questions')}: ${localeData.questionsTotal}`}
            </Flex>
            <Flex>
              {`${t('tutorial.estimated_time')}:  ${localeData.estimatedTime} min`}
            </Flex>
          </Flex>
        </Row>
      </Row>
    </LayoutPageMobile>
  );
}
