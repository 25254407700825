// @ts-ignore
import { ArrowDownIcon, ArrowUpIcon } from '@keyslabor/shared/assets';
import { Flex, Text } from '../index.ts';
import { Select as SelectBase, SelectProps } from 'antd';
import { useIsMobile } from '../../hooks/useBreakPoint.ts';
import React, { useState } from 'react';

import styled, { css } from 'styled-components';

const SelectStyled = styled(SelectBase)(
  ({ theme }) => css`
    .ant-select-selector {
      min-width: 100px;
      color: ${theme.colors.grayText};
      height: 40px !important;
      box-shadow: 0px 4px 4px rgba(93, 103, 160, 0.15),
        0px 4px 16px rgba(93, 103, 160, 0.08) !important;
      border: none !important;
      display: flex;
      align-items: center;
    }
  `
);

export function Select({
  label,
  required,
  ...restProps
}: SelectProps & { label?: string; required?: boolean }) {
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();
  return (
    <Flex width="100%" flexDirection="column">
      {label ? (
        <Flex>
          <Text fontSize={isMobile ? '16px' : '14px'} fontWeight="500" mb="6px">
            {label}
          </Text>
          {required ? (
            <Text color="#EB5757" ml="2px">
              *
            </Text>
          ) : null}
        </Flex>
      ) : null}
      <SelectStyled
        onDropdownVisibleChange={(visible) => {
          setOpen(visible);
        }}
        suffixIcon={open ? <ArrowUpIcon /> : <ArrowDownIcon />}
        {...restProps}
      />
    </Flex>
  );
}

export default Select;
