import { post } from './fetchWrapper';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  InterviewPreviewOutput,
  QuestionsAnswersInput,
  QuestionsOutput,
  StatusInput,
  StatusOutput,
} from './typesApi';
import { endpoints } from '@/constants';

export const api = {
  getStatus: (input: StatusInput) =>
    post<StatusOutput, StatusInput>(endpoints.status, input),
  getQuestions: (input: QuestionsAnswersInput) =>
    post<QuestionsOutput, QuestionsAnswersInput>(
      endpoints.questions,
      input,
    ),
  postAnswersConfirmation: (input: QuestionsAnswersInput) =>
    post<boolean, QuestionsAnswersInput>(
      endpoints.answersConfirmation,
      input,
    ),
  postAnswersUploadRejected: (input: QuestionsAnswersInput) =>
    post<boolean, QuestionsAnswersInput>(
      endpoints.answersUploadRejected,
      input,
    ),
  postInterviewPreview: (input: StatusInput) =>
    post<InterviewPreviewOutput, StatusInput>(
      endpoints.interviewPreview,
      input,
    ),
};

export const useGetStatus = (invitationKey: string | null) => {
  return useQuery({
    queryKey: ['status', invitationKey],
    queryFn: async () => api.getStatus({ invitation_key: invitationKey }),
    enabled: !!invitationKey,
  });
};

export const useGetQuestions = ({
                                  input,
                                  onError,
                                }: {
  input: Omit<QuestionsAnswersInput, 'invitation_key'> & {
    invitation_key: string | null;
  };
  onError?: (err: unknown) => void;
}) => {
  return useQuery({
    queryKey: ['questions', input],
    queryFn: async () => api.getQuestions(input as QuestionsAnswersInput),
    enabled: !!input.invitation_key,
    onError: onError,
  });
};

export const useGetQuestionsMutation = ({ onError, onSuccess }) => {
  return useMutation({
    mutationKey: ['questions'],
    mutationFn: async (input: QuestionsAnswersInput) => api.getQuestions(input),
    onError,
    onSuccess,
  });
};

export const usePostAnswersConfirmation = () => {
  return useMutation({
    mutationFn: async (input: QuestionsAnswersInput) => api.postAnswersConfirmation(input),
  });
};

export const usePostAnswersUploadRejected = () => {
  return useMutation({
    mutationFn: async (input: QuestionsAnswersInput) => api.postAnswersUploadRejected(input),
  });
};

export const useInterviewPreview = (input: StatusInput) => {
  return useQuery({
    enabled: !!input?.invitation_key,
    queryKey: ['interviewPreview'],
    queryFn: async () => api.postInterviewPreview(input),
  });
};
